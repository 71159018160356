import { Typography, Box, Button, Link } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import './NotAuthorizedPage.css';

const authRedirect = 'https://dev.azure.com/GlobalEngg/PromoPlus%20%28NGPS%29/_wiki/wikis/PromoPlus%20%28NGPS%29.wiki/13778/How-to-request-access-to-Self-Service-2-';

function NotAuthorizedPage() {

    const location = useLocation();
    const navigate = useNavigate();

    const handleBackHome = () => {
        if (location.pathname === '/') {
            window.location.reload();
        } else {
            navigate('/');
        }
    };

    return (
        <Box className='section'>
            <Typography variant='subheader'>You are not authorized to see this page. Please, request access <Link href={authRedirect} target="_blank" rel="noopener noreferrer">here</Link>.</Typography>
            <Button className='back-button' variant="contained" color="primary" onClick={handleBackHome}>Back to Home</Button>
        </Box>
    )
}

export { NotAuthorizedPage }
