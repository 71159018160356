import { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "../parts/Header/Header";
import { useLocation } from "react-router-dom";

interface AppData {
    user: CognitoUser | null;
    authLoading: boolean;
    attemptToSignIn: boolean;
    loginError: boolean;
  }

  export const useAuth = () => {
    const [appData, setData] = useState<AppData>({
        user: null,
        authLoading: true,
        attemptToSignIn: false,
        loginError: false
    });
    const { hash } = useLocation();
    const userEmail = appData.user && appData.user.email;

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signOut':
                case 'oAuthSignOut':
                    sessionStorage.removeItem('allowedPages')
                    sessionStorage.removeItem('willReturnFromRedirect')
                    setData({
                        user: null,
                        authLoading: true,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
                case 'oAuthSignIn':
                    setData({
                        user: data,
                        authLoading: false,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
                case 'oAuthSignIn_failed':
                    sessionStorage.setItem('willReturnFromRedirect', 'true')
                    setData({
                        user: null,
                        authLoading: true,
                        attemptToSignIn: true,
                        loginError: false
                    })
                    break;
                case 'cognitoHostedUI_failure':
                    setData({
                        user: null,
                        authLoading: true,
                        attemptToSignIn: true,
                        loginError: true
                    })
                    break;
            }
        });

        const regExp = /error_description=(PreTokenGeneration).+(Authorization\Wfailed).+(Cognito\Wgroup\Wmissing)/;
        if (regExp.test(hash)) {
            const res = regExp.exec(hash);

            if (res) {
                console.error(`LogIn Error at ${res.groups?.error_location}. Error Message: ${res.groups?.error_msg}. ${res.groups?.cognito_group_error}`);

                sessionStorage.removeItem('willReturnFromRedirect');

                setData({
                    user: null,
                    authLoading: false,
                    attemptToSignIn: false,
                    loginError: true
                });

                return unsubscribe;
            }
        }

        if (!userEmail) {
            Auth.currentAuthenticatedUser()
                .then(currentUser => Auth.userSession(currentUser))
                .then((session) => {
                    const tokenPayload = session.getIdToken().payload;
                    sessionStorage.setItem('allowedPages', tokenPayload.allowedPages);

                    setData({
                        user: tokenPayload,
                        authLoading: false,
                        attemptToSignIn: true,
                        loginError: false
                    });
                })
                .catch((e) => {
                    console.error('Auth: ', e);
                    sessionStorage.removeItem('willReturnFromRedirect');
                });
        }

        return unsubscribe;
    }, [userEmail, hash]);

    return appData;
  };
