import { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getPrizeDrawParticipations, getLotteryParticipations } from '../../../graphql/queries';
import { Box, Typography, Autocomplete, Button, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { ConfigType } from '../../../types/configuration';
import { PrizeDrawParticipationsRes, LotteryParticipationsRes } from '../../../types/componentTypes/editPromotion'
import { TooltipText } from '../../../components/inputComponents/TooltipText';
import { Notification } from '../../../common/Notification/Notification';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';
import { notificationType } from '../../../types/notifications';
import { ToolTipPlacement } from '../../../types/componentTypes/tooltip';
import { FullParticipationExportRes, CustomParticipationExportRes} from '../../../types/componentTypes/ParticipationExportTypes';
import { defaultNotifState } from '../../../constants/currency-constants';
import './ParticipationExport.css'
import WinnersTable from './WinnersTable';
import ExportStatusMessage from './ExportStatusMessage';

const options = [
    { 'label': 'Full Export', 'value': 'fullExport' },
    { 'label': 'Custom Export (number of winners)', 'value': 'custom' }
];

const defaultNumberOfWinners = 1;

const ParticipationExport = ({ config }: { config: ConfigType }) => {
    const [configData] = useState<ConfigType>(config);
    const [selectedOption, setSelectedOption] = useState<string | undefined>()
    const [numberOfWinner, setNumberOfWinner] = useState<number>(defaultNumberOfWinners)
    const [generateExport, setGenerateExport] = useState<boolean>(false)
    const [dataToExport, setDataToExport] = useState<FullParticipationExportRes | CustomParticipationExportRes | undefined>();
    const [csvMessage, setCSVMessage] = useState<boolean>(false);
    const [notificationState, setNotificationState] = useState<notificationType>(defaultNotifState);
    const [isBatchForWinners, setIsBatchForWinners] = useState<boolean>(false);

    const handleOptionSelected = (_, eventValue) => {
        setSelectedOption(eventValue?.value);
        setGenerateExport(false)
        setDataToExport(undefined)
    }

    const handleGenerate = async () => {
        setGenerateExport(true);
        setIsBatchForWinners(false);
        let errContent = '';
        try {
            if (selectedOption === 'fullExport') {
                const csvData = await API.graphql(graphqlOperation(getPrizeDrawParticipations, { prizeDrawLambdaInput: { configurationId: configData.configurationId } })) as GraphQLResult<PrizeDrawParticipationsRes>
                const response = JSON.parse(csvData?.data?.getPrizeDrawParticipations?.body)
                if (response?.customErrorMessage) {
                    errContent = response.customErrorMessage;
                    throw new Error(response.customErrorMessage);
                }
                setDataToExport(response)
                setGenerateExport(false)
                if (response.message) setCSVMessage(true)
            }
            if (selectedOption === 'custom') {
                const csvData = await API.graphql(graphqlOperation(getLotteryParticipations, {
                    lotteryLambdaInput: {
                        configurationId: configData.configurationId,
                        numberOfWinners: numberOfWinner,
                    }
                })) as GraphQLResult<LotteryParticipationsRes>
                const parseData = JSON.parse(csvData?.data?.getLotteryParticipations?.body);
                if(parseData.isBatch === 'true')
                {
                    setIsBatchForWinners(true);
                }
                setDataToExport(parseData);
                setGenerateExport(false)
            }
        } catch (err) {
            setNotificationState({
                open: true,
                title: 'Generating participations export failed',
                content: errContent,
                level: "error"
            })
            setGenerateExport(false);
            setSelectedOption(undefined);
            console.error("Generating export failed with: ", err);
        }
    }

    const handleNumberOfWinnerOnChange = (e) => {
        setDataToExport(undefined);
        setNumberOfWinner(parseInt(e.target.value) || 0);
        setGenerateExport(false);
    }

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
        setNotificationState({
            open: true,
            title: 'Participation CSV Link copied!',
            content: '',
            level: 'success',
        });
    };

    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <Box className='pe-heading'>
                <Typography variant='h3'>Participation Export</Typography>
            </Box>
            <Box className='pe-main-box'>
                <Box className='pe-selector'>
                    <TooltipText
                        text={'Select export type'}
                        textVariant='body1'
                        iconSize='medium'
                        styleName='tooltip'
                        tooltip={'You can choose between full export or custom export. \
                            The full export consists of all participations till the moment of the download. \
                            The custom export has only randomly selected winners based on the value populated in the number of winners field.'
                        }
                        placement={ToolTipPlacement.RightEnd} />
                    <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.label}
                        getOptionDisabled={(option) =>
                            option.value === selectedOption
                        }
                        renderInput={(params) => <TextFieldWrapper {...params} />}
                        onChange={handleOptionSelected}
                    />
                    {(selectedOption === 'custom') &&
                        <Box className='custom-participation-box'>
                            <TooltipText
                                text='Number of winners'
                                textVariant='body1'
                                styleName='number-of-winner-tooltip'
                                iconSize='medium'
                                tooltip='The system will randomly select the number of winners entered in this field.'
                                placement={ToolTipPlacement.RightEnd} />
                            <TextField
                                error={numberOfWinner < 1}
                                className='pe-button'
                                value={numberOfWinner}
                                onChange={(e) => handleNumberOfWinnerOnChange(e)}
                                helperText="Please enter a positive number"
                            />
                            {isBatchForWinners && dataToExport && (
                                <ExportStatusMessage
                                    message='Due to the large number of participants, the file is still in progress. Please check the link below again in 15 minutes.'
                                    csvUrl={dataToExport.csv} 
                                    onCopy={() => copyToClipboard(dataToExport.csv)} 
                                />
                            )}
                            
                        </Box>
                    }
                    <Box className='pe-button-box'>
                        {generateExport &&
                            <Typography variant='body1' className='pe-message'>Please wait, file is being processed. It might take some time.</Typography>
                        }
                        {(selectedOption && !dataToExport) && <Button variant='contained' color='primary' onClick={handleGenerate} disabled={generateExport || numberOfWinner < 1}>Generate Export</Button>}
                        {dataToExport && !csvMessage && !isBatchForWinners && <Button
                            variant='contained'
                            color='primary'
                            startIcon={<DownloadIcon />}
                            className='pe-button'
                            onClick={() => window.open(dataToExport.csv, "_blank")}
                        >Download</Button>}
                        {dataToExport && csvMessage && selectedOption === 'fullExport' &&
                            (
                                <ExportStatusMessage
                                    message='Due to the large number of participants, the file is still in progress. Please check the link below again in 15 minutes.'
                                    csvUrl={dataToExport.csv} 
                                    onCopy={() => copyToClipboard(dataToExport.csv)} 
                                />
                            )
                        }
                    </Box>
                </Box>
            </Box>
            {
                dataToExport && !isBatchForWinners && selectedOption === 'custom' &&
                <WinnersTable data-testid="winners-table" data={dataToExport as CustomParticipationExportRes} />
            }
        </>
    )
}

export { ParticipationExport }