import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import './App.css';
import { CreateCampaignPage } from './pages/CreateCampaign/CreateCampaignPage';
import { ListCampaignsPage } from './pages/ListCampaigns/ListCampaignsPage'
import { HomePage } from './pages/Home/HomePage';
import { LoginPage } from './pages/Login/LoginPage';
import { EditPromotionPage } from './pages/EditPromotion/EditPromotionPage';
import Header from './parts/Header/Header'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { EditCampaignPage } from './pages/EditCampaignPage/EditCampaignPage';
import { AssignPromotionPage } from './pages/AssignPromotion/AssignPromotionPage';
import MUItheme from './MuiTheme'
import ProgressBar from './common/ProgressBar/ProgressBar';
import GridSkeleton from './common/Skeletons/GridSkeleton';
import { AddPrizePage } from './pages/AddPrize/AddPrizePage';
import ListCurrenciesPage from './pages/Currencies/ListCurrenciesPage';
import CurrencyConfiguratorPage from './pages/Currencies/CurrencyConfiguratorPage';
import { CurrencyPaths } from './constants/currency-constants';
import { AdminPanelPaths } from './pages/AdminPanel/Permissions/adminpanel-constants';
import { AdminPanelPage } from './pages/AdminPanel/AdminPanelPage';
import { LicenseInfo } from '@mui/x-license-pro';
import { NotAuthorizedPage } from './pages/NotAuthorizedPage/NotAuthorizedPage';
import { useAuth } from './hooks/useAuth';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');

const theme = createTheme(MUItheme);
declare module '@mui/material/styles' {
    interface TypographyVariants {
        subheader: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        subheader?: React.CSSProperties;
    }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        subheader: true;
    }
}

const homeUrl = `${window.location.origin}/`;
const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
        ...awsconfig.oauth,
        redirectSignIn: homeUrl,
        redirectSignOut: homeUrl,
        responseType: "code",
    }
}

Amplify.configure(updatedAwsConfig);

const App = () => {
    const appData = useAuth()

    if (appData.loginError) {
        return (
            <ThemeProvider theme={theme}>
                <Header auth={appData.user} />
                <NotAuthorizedPage />
            </ThemeProvider>
        )
    }

    if (appData.authLoading && appData.attemptToSignIn) {
        return (
            <ThemeProvider theme={theme}>
                <Header auth={appData.user} />
                <ProgressBar />
                <GridSkeleton items={4} />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Header auth={appData.user} />
            {
                !appData.user &&
                sessionStorage.getItem('willReturnFromRedirect') === 'true' &&
                <GridSkeleton items={4} />
            }
            <div className="bodyWrapper">
                <Routes>
                    {
                        !appData.user &&
                        !appData.attemptToSignIn &&
                        sessionStorage.getItem('willReturnFromRedirect') !== 'true' &&
                        <Route path='/' element={<LoginPage />} />
                    }

                    {appData.user && <Route path='/' element={<HomePage />} />}
                    <Route path='/home' element={
                        <PrivateRoute>
                            <HomePage />
                        </PrivateRoute>
                    } />
                    <Route path="/createCampaign" element={
                        <PrivateRoute>
                            <CreateCampaignPage />
                        </PrivateRoute>
                    } />
                    <Route
                        path="/assignPromotion/:promotionId"
                        element={
                            <PrivateRoute>
                                <AssignPromotionPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path='/listCampaigns' element={
                        <PrivateRoute>
                            <ListCampaignsPage />
                        </PrivateRoute>
                    } />
                    <Route
                        path="/editCampaign/:campaignId"
                        element={
                            <PrivateRoute>
                                <EditCampaignPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId/prizeTab"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage initialTab={"prizes"} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/editPromotion/:promotionId/winningMomentsTab"
                        element={
                            <PrivateRoute>
                                <EditPromotionPage initialTab={"winningMoments"} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/addPrize/:campaignId/:configurationId"
                        element={
                            <PrivateRoute>
                                <AddPrizePage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.ListCurrencies}
                        element={
                            <PrivateRoute>
                                <ListCurrenciesPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.CreateCurrency}
                        element={
                            <PrivateRoute>
                                <CurrencyConfiguratorPage isEdit={false} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={CurrencyPaths.EditCurrency}
                        element={
                            <PrivateRoute>
                                <CurrencyConfiguratorPage isEdit={true} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={AdminPanelPaths.AdminPanel}
                        element={
                            <PrivateRoute>
                                <AdminPanelPage initialTab={'permissions'} />
                            </PrivateRoute>
                        }
                    />
                    <Route path='/login' element={<LoginPage />} />
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
