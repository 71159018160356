import { Navigate, useLocation } from "react-router-dom";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { NotAuthorizedPage } from "../../pages/NotAuthorizedPage/NotAuthorizedPage";

interface PrivateRouterProps {
    children: ReactJSXElement
}

const PrivateRoute = ({ children }: PrivateRouterProps) => {
    const location = useLocation();
    const allowedPages = sessionStorage.getItem('allowedPages');
    const authorized = (pages: string | null, path: string) => {
        if (!pages) {
            return;
        }

        return pages.split(' ').findIndex((page) => path.includes(page)) > -1;
    };

    if (!allowedPages) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else if (authorized(allowedPages, location.pathname)) {
        return children;
    } else {
        return (
            <NotAuthorizedPage />
        );
    }
}

export default PrivateRoute;
