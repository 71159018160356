import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import awsconfig from '../../../../aws-exports';
import { appendPublicDomainName } from '../fileUploadHelpers';
import { UploadedFileActionsProps } from '../../../../types/componentTypes/FileUploadTypes'

const UploadedFilesActionButtons = ({
    fileName,
    usePublicDomain,
    filePath,
    handleDeleteFile,
    canEdit,
    setOpenEditModal,
    isEdit,
    uploadedFilePath
}: UploadedFileActionsProps ) => {

    const getUrl = () => {
        if (isEdit) return uploadedFilePath;
        if (usePublicDomain) {
            return appendPublicDomainName({
            path: `${filePath}/${decodeURI(fileName)}`,
            bucketName: awsconfig.aws_user_files_s3_bucket,
            });
        }
        return fileName;
    }

    return (
        <>
            {canEdit && (
                <Grid item xs={1} className='delete-file-grid'>
                    <EditIcon className='delete-icon' onClick={() => setOpenEditModal({
                        open: true,
                        url: getUrl()
                    })}
                    />
                </Grid>
            )}
            <Grid item xs={canEdit ? 1 : 2} className='delete-file-grid'>
                <DeleteIcon
                    className='delete-icon'
                    onClick={() => {
                        handleDeleteFile(fileName);
                    }}
                />
            </Grid>
        </>
    )
}

export { UploadedFilesActionButtons }
