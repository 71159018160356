import { useEffect, useState, useRef, useMemo } from "react";
import { configureStorage, validateFileAndHeader,getFileName } from "./fileUploadHelpers";
import { FileUploadProps, ProgressInfo, ProgressInfoMapRecord } from "../../../types/componentTypes/FileUploadTypes";
import { MainFileUploadComponent } from "./components/MainFileUploadComponent";
import { deleteFileFromStorage } from "./services/uploadActions";
import "./FileUpload.css";

const PrizeImgFileUpload = ({
    isEdit,
    backstepDone,
    placeholder,
    filePath,
    maxFiles = 1,
    uploadCallback,
    deleteCallback,
    usePublicDomain = false,
    fileAccess = "public",
    bucket = "private",
    acl = "public-read",
    cachedFiles = [],
    headerValidation = false,
    customAllowedFileTypes,
    canEdit = false,
    setOpenEditModal,
    progressKey,
    filesProcessed,
    errorText,
    setNotificationState,
    headerValidatorFunc,
}: FileUploadProps) => {
    const transformProcessedFiles = useMemo(() => {
        const transformFiles = (): ProgressInfoMapRecord => {
            return Object.keys(filesProcessed).reduce((acc, key) => {
                acc[key] = filesProcessed[key].map(value => ({
                    fileName: getFileName(isEdit, usePublicDomain, value, filePath),
                    percent: 100
                }));
                return acc;
            }, {})
        }
        return transformFiles();
    }, [usePublicDomain, filePath, filesProcessed, isEdit]);

    const [progressInfo, setProgress] = useState<ProgressInfoMapRecord>(transformProcessedFiles);
    const hiddenFileInput = useRef<HTMLInputElement>();
    const [files, setFile] = useState<File[]>([]);
    let progressFiles = progressInfo

    useEffect(() => {
        configureStorage(fileAccess);
    }, [fileAccess]);


    const updateProgressInfo = (progressFileInfo) => {
        setProgress(prev => {
            let newProgress = { ...prev };
            if (!progressFiles[progressKey]) {
                progressFiles[progressKey] = [];
            }
            const existingIndex = progressFiles[progressKey].findIndex(
                fileInfo => fileInfo.fileName === progressFileInfo.fileName
            );
            if (existingIndex !== -1) {
                progressFiles[progressKey][existingIndex] = progressFileInfo;
            } else {
                progressFiles[progressKey].push(progressFileInfo);
            }
            newProgress = { ...newProgress, ...progressFiles }

            return newProgress;
        });
    }

    const handleDeleteFile = async (fileName: string) => {
        try {
            const newFiles = files.filter((file: File) => file.name !== fileName);
            const newProgressInfo = {
                ...progressInfo,
                [progressKey]: (progressInfo as ProgressInfoMapRecord)[progressKey].filter(
                    (obj: ProgressInfo) => obj.fileName !== fileName
                )
            }
            await deleteFileFromStorage({
                bucket,
                filePath,
                fileName,
                deleteCallback,
                usePublicDomain
            })

            setProgress(newProgressInfo);
            setFile(newFiles);
            hiddenFileInput.current.value = null
        } catch (err) {
            console.error('Deleting file failed with:', err);
        }
    };

    const prepareValidFiles = async (files: File[]) => {
        const validFiles: File[] = [];
        await Promise.all(
            files.map(async (file: File) => {
                const isValid = await validateFileAndHeader(file, customAllowedFileTypes, headerValidation, headerValidatorFunc, setNotificationState);

                if (isValid) {
                    validFiles.push(file);
                    const progressFilesUpdate = { ...progressFiles };
                    if (!progressFilesUpdate[progressKey]) {
                        progressFilesUpdate[progressKey] = [];
                    }
                    progressFilesUpdate[progressKey].push({ fileName: file.name, percent: 0 });
                    progressFiles = progressFilesUpdate
                }
            })
        );

        return validFiles;
    };

    const getTotalFilesUploaded = () => {
        let totalLength = 0;

        for (let lang in filesProcessed) {
            totalLength += filesProcessed[lang].length;
        }
        return totalLength
    }

    return (
        <MainFileUploadComponent
            placeholder={placeholder}
            files={files}
            cachedFiles={cachedFiles}
            textInputFiles={progressInfo[progressKey]}
            usePublicDomain={usePublicDomain}
            filePath={filePath}
            maxFiles={maxFiles}
            errorText={errorText}
            hiddenFileInput={hiddenFileInput}
            progressInfo={progressInfo?.[progressKey]}
            handleDeleteFile={handleDeleteFile}
            canEdit={canEdit}
            setOpenEditModal={setOpenEditModal}
            backstepDone={backstepDone}
            isEdit={isEdit}
            prepareValidFiles={prepareValidFiles}
            setNotificationState={setNotificationState}
            updateProgressInfo={updateProgressInfo}
            uploadCallback={uploadCallback}
            bucket={bucket}
            acl={acl}
            setProgress={setProgress}
            setFile={setFile}
            allFiles={getTotalFilesUploaded()}
        />
    );
};

export { PrizeImgFileUpload };
