import { useState, useEffect } from 'react';
import { Container, Box, Button, Typography, Breadcrumbs, Link } from '@mui/material';
import { generateWinningMoments } from '../../graphql/queries';
import { updatePrizeCatalogueTable } from '../../graphql/mutations';
import { Link as routerLink } from 'react-router-dom';
import { Formik, getIn } from 'formik';
import { API, graphqlOperation } from 'aws-amplify';
import { TooltipText } from '../inputComponents/TooltipText';
import { InputNumberSpinner } from '../inputComponents/InputNumberSpinner';
import { CheckboxComponent } from '../inputComponents/CheckboxComponent';
import { RadioButton } from "../../components/inputComponents/RadioButton";
import { InputComponent } from '../inputComponents/InputComponent';
import { DialogBox } from '../../common/Dialog/DialogBox'
import GenericSpinner from '../../common/Spinners/GenericSpinner';
import { generateWinningMomentsValidationSchema } from './generateWinningMomentsValidationSchema'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


let timestampDistributionTooltipText: string = 'The higher the number, the more random the distribution. If you select 0, the winning moments will be distributed evenly. If 100 is selected each moment will occur at a random interval';
let prizeDistributionTooltipText: string = 'Winning moments are assigned to each prize. Increasing the Prize Distribution defect value randomizes the prize sequence when the prize timelines coincide. Read more on the wiki'
let validityTimeTooltipText: string = 'This option allows the user to define the period between when a winning moment becomes available until its expiration'
let automaticWinningMomentUploadDialogueText: string = 'This will generate and upload winning moments automatically. Do you want to proceed?'
let winnningMomentUploadDialogueText: string = 'This will generate winning moments and allow you to download the winning moments file, but not upload it automatically. Do you want to proceed?'
let downloadMomentsFileInformationText: string = 'The winning moments file will not be available after this screen is closed. If you would like to download it, please do so before exiting.'
let dialogueButtonLabels: string[] = ['Cancel', 'Yes']

let customRadioGroupStyles = { marginRight: "15px" }

interface initialFormikState {
    showWinningMomentExpiration: string,
    automaticUpload: boolean,
    generatedMoments: string,
    showCompletionScreen: boolean,
    showDialogue?: boolean,
    showGenerationError: Boolean,
    generatorType: string,
    configurationId: string,
    startDate: number,
    endDate: number,
    prizeDistributionDefect: number,
    timestampDistributionDefect: number,
    winningMomentExpiration: number | string,
    prizeIds: string[],
    prizeInput: {
        automaticUpload: boolean,
        generatorType: string,
        configurationId: string,
        prizeParams: prizeParam[]
    }

};

interface prizeParam {
    prizeId: string,
    startDate: number,
    endDate: number,
    timestampDistributionDefect: number,
    winningMomentExpiration: number
};

interface inputProps {
    configurationId: string,
    prizeInput: prizeParam[],
    showGeneratorScreen?: any,
    isForSelectedPrizes?: boolean
};

type gqlGenerateMomentsRes = {
    generateWinningMoments: {
        body: string
    }
};

let initialState: initialFormikState = {
    showWinningMomentExpiration: 'false',
    automaticUpload: false,
    generatedMoments: '',
    showCompletionScreen: false,
    showGenerationError: false,
    showDialogue: false,
    generatorType: 'basic',
    configurationId: '',
    startDate: 0,
    endDate: 0,
    prizeDistributionDefect: 30,
    timestampDistributionDefect: 30,
    winningMomentExpiration: 0,
    prizeIds: [],
    prizeInput: {
        automaticUpload: false,
        generatorType: 'advanced',
        configurationId: '',
        prizeParams: [
            {
                prizeId: '',
                startDate: 0,
                endDate: 0,
                timestampDistributionDefect: 30,
                winningMomentExpiration: 0
            }
        ]
    }
};


function GenerateWinningMoments({ configurationId, prizeInput, showGeneratorScreen, isForSelectedPrizes }: inputProps): JSX.Element {
    const [useAdvancedGeneration, setUseAdvancedGeneration] = useState(true)
    const [momentsGenerated, setMomentsGenerated] = useState(false)

    const generateWinningMomentsFunction = async (input: any) => {
        let fetchedData = (await API.graphql(graphqlOperation(generateWinningMoments, { winningMomentsGeneratorLambdaInput: input }))) as {
            data: gqlGenerateMomentsRes
        };
        const resultBody = JSON.parse(fetchedData?.data?.generateWinningMoments?.body);
        if (resultBody?.errCode || resultBody?.errorCode) throw new Error(resultBody.message);
        return fetchedData?.data?.generateWinningMoments?.body || '';
    };

    const updatePrizeObjects = async (input: any) => {
        let response = await API.graphql(graphqlOperation(updatePrizeCatalogueTable, { input: { configuration_id: configurationId, has_expirable_moments: true, prize_id: input.prizeId } }));
        return response;
    };

    const showCompletionScreen = async (formikContext: any): Promise<void> => {
        if (JSON.stringify(formikContext.errors) === '{}') {
            let generationInput: any
            if (useAdvancedGeneration) {
                initialState.prizeInput.prizeParams.forEach((prizeParams: { winningMomentExpiration: any; }) => prizeParams.winningMomentExpiration = formikContext.values.winningMomentExpiration || 0);
                initialState.prizeInput.prizeParams.forEach((prizeParams: { timestampDistributionDefect: any; }) => prizeParams.timestampDistributionDefect = formikContext.values.timestampDistributionDefect);
                initialState.prizeInput.automaticUpload = formikContext.values.automaticUpload;
                generationInput = formikContext.values.prizeInput
            }
            if (!useAdvancedGeneration) {
                initialState.prizeDistributionDefect = formikContext.values.prizeDistributionDefect;
                initialState.timestampDistributionDefect = formikContext.values.timestampDistributionDefect;
                formikContext.setFieldValue('winningMomentExpiration', parseInt(formikContext.values.winningMomentExpiration))
                delete formikContext.values.showWinningMomentExpiration
                delete formikContext.values.generatedMoments
                delete formikContext.values.showCompletionScreen
                delete formikContext.values.showDialogue
                delete formikContext.values.showGenerationError
                delete formikContext.values.prizeInput
                generationInput = formikContext.values;
            }

            if (formikContext.values.winningMomentValue !== 0) {
                prizeInput.forEach(prizeObject => {
                    try {
                        updatePrizeObjects(prizeObject)
                    } catch (e) {
                        console.error("Prize objects failed with: ", e);
                    };
                })
            };

            try {
                generationInput.winningMomentExpiration = parseInt(generationInput.winningMomentExpiration)
                await generateWinningMomentsFunction(generationInput).then((moments) => {
                    formikContext.setFieldValue('showCompletionScreen', true);
                    formikContext.setFieldValue('generatedMoments', moments);
                    formikContext.setFieldValue('showGenerationError', false);
                    setMomentsGenerated(true);
                })
            } catch (e) {
                console.error("Generation failed with: ", e);
                formikContext.setFieldValue('showGenerationError', true)
                formikContext.setFieldValue('showCompletionScreen', true);
            };
        }
    };

    const initialParameterSetup = (passedConfigurationId: string, passedPrizeInfo: prizeParam[]) => {
        const comparisonArray: number[] = [];
        passedPrizeInfo.forEach(prizeParams => {
            const timeStampValue = prizeParams.startDate + prizeParams.endDate;
            if (comparisonArray.indexOf(timeStampValue) === -1) {
                comparisonArray.push(timeStampValue);
            }
        });
        const advancedGeneration: boolean = ((comparisonArray.length > 1) || (passedPrizeInfo.length === 1)) ? true : false;
        if (advancedGeneration) {
            initialState.prizeInput.configurationId = passedConfigurationId;
            initialState.prizeInput.prizeParams = passedPrizeInfo;
        } else {
            initialState.configurationId = passedConfigurationId;
            initialState.startDate = passedPrizeInfo[0].startDate;
            initialState.prizeIds = passedPrizeInfo.map(item => item.prizeId);
            initialState.endDate = passedPrizeInfo[0].endDate;
        }
        setUseAdvancedGeneration(advancedGeneration)
    };

    useEffect(() => {
        initialParameterSetup(configurationId, prizeInput);
    }, [configurationId, prizeInput])

    const handleDialogueClose = (formikContext: any) => {
        formikContext.setFieldValue('showDialogue', false);
    };

    return (
        <Container maxWidth="lg" margin-top="120" background-color="white">
            <Box alignItems="center">
                <Formik validateOnBlur={true} validateOnChange={true} enableReinitialize validationSchema={generateWinningMomentsValidationSchema} initialValues={initialState} onSubmit={(values: any) => { console.log(values) }} >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            {formik.values.showCompletionScreen === false &&
                                <div>
                                    <Breadcrumbs sx={{ position: 'relative', marginLeft: "45%", marginTop: "15px" }}>
                                        <Link underline="hover" fontSize="15px" color="inherit" href="/listCampaigns">
                                            Campaigns
                                        </Link>
                                    </Breadcrumbs>
                                    <Typography variant='h4' sx={{ marginTop: "30px", marginBottom: "30px" }} >
                                        Winning Moments
                                    </Typography>
                                    <Typography variant='h4' sx={{ marginTop: "30px", marginBottom: "30px", fontSize: 24 }}>
                                        {isForSelectedPrizes ? "Generate Winning Moments for Selected Prize(s)" : "Generate Winning Moments for All Prizes"}
                                    </Typography>
                                    <Box sx={{ display: "inline-grid" }}>
                                        {(useAdvancedGeneration === false) &&
                                            <Box>
                                                <div style={{ marginTop: "15px" }}>
                                                    <TooltipText styleName="tooltipGridAdjusted" text='Prize Distribution' iconSize='medium' textVariant='body2' tooltip={prizeDistributionTooltipText} />
                                                </div>
                                                <InputNumberSpinner fieldName={'prizeDistributionDefect'} incrementValue={1} />
                                            </Box>
                                        }
                                        <Box>
                                            <div style={{ marginTop: "15px", marginBottom: "3px" }}>
                                                <TooltipText styleName="tooltipGridAdjusted" text='Timestamp Distribution' iconSize='medium' textVariant='body2' tooltip={timestampDistributionTooltipText} />
                                            </div>
                                            <InputNumberSpinner fieldName={'timestampDistributionDefect'} incrementValue={1} />
                                        </Box>
                                        <Box className='radio-group-form-adjusted'>
                                            <div style={{ marginTop: "20px", marginBottom: "-4px" }}>
                                                <TooltipText styleName="tooltipGridAdjusted" text='Validity Time' iconSize='medium' textVariant='body2' tooltip={validityTimeTooltipText} />
                                            </div>
                                            <div className='radioGroupAdjusted' style={{ marginRight: "32%" }}>
                                                <RadioButton rowsToApplyIndividualStylesFor={1} individualRowStyle={customRadioGroupStyles} labelVariant="body2" formik={formik} values={[
                                                    { value: 'false', label: 'Winning Moments don`t expire' },
                                                    { value: 'true', label: 'Expirable winning moments' }
                                                ]}
                                                    radioGroupName={'showWinningMomentExpiration'}
                                                    customAction={() => {
                                                        formik.setFieldValue('showWinningMomentExpiration', formik.values.showWinningMomentExpiration === 'true' ? 'false' : 'true');
                                                    }}
                                                />
                                            </div>
                                            {
                                                formik.values.showWinningMomentExpiration === 'true' &&
                                                <InputComponent
                                                    inputClassName='validityTimeBoxStyles'
                                                    formik={formik}
                                                    inputValue={getIn(formik.values, 'winningMomentExpiration')}
                                                    formikLabel={'winningMomentExpiration'}
                                                    textVariant='body2'
                                                    label='Validity time in milliseconds'
                                                />
                                            }
                                        </Box>
                                        <div className='automaticUploadCheckboxStyles'>
                                            <CheckboxComponent labelVariant="body2" fieldName={'automaticUpload'} label='Automatically upload generated winning moments' />
                                        </div>
                                    </Box>
                                    <Box>
                                        <Button
                                            sx={{ marginTop: "15px", width: "142px", marginRight: "10px", fontStyle: "bold", paddingLeft: "5px", paddingRight: "5px" }}
                                            variant="outlined"
                                            component={routerLink} to={`/editPromotion/${configurationId}/winningMomentsTab`}
                                            onClick={() => showGeneratorScreen({ showScreen: false, prizeParams: '' })}
                                        >Cancel</Button>
                                        <Button
                                            sx={{ marginTop: "15px", width: "142px", marginRight: "10px", fontStyle: "bold", paddingLeft: "5px", paddingRight: "5px" }}
                                            variant="contained"
                                            onClick={() => formik.setFieldValue('showDialogue', true)}
                                        >Generate</Button>
                                        {formik.values.showDialogue &&
                                            <DialogBox
                                                isOpen={true}
                                                buttonDisabled={false}
                                                actionButtonLabels={dialogueButtonLabels}
                                                actionButtonHandlers={[handleDialogueClose(formik), showCompletionScreen(formik)]}
                                                dialogCloseHandler={handleDialogueClose(formik)}
                                                dialogContent={formik.values.automaticUpload ? automaticWinningMomentUploadDialogueText : winnningMomentUploadDialogueText}
                                            />}
                                    </Box>
                                </div>
                            }
                            {formik.values.showGenerationError &&
                                <div>
                                    <Typography variant='h4' sx={{ fontSize: 28, marginTop: "15px" }} >
                                        Generation has failed!
                                    </Typography>
                                    <ErrorOutlineIcon sx={{ fontSize: 80, color: "red", marginTop: "50px", marginBottom: "50px" }} />
                                    <div>
                                        <Button
                                            sx={{ width: "155px", marginRight: "10px", fontStyle: "bold", paddingLeft: "5px", paddingRight: "5px" }}
                                            variant="outlined"
                                            component={routerLink} to={`/editPromotion/${configurationId}/winningMomentsTab`}
                                            onClick={() => showGeneratorScreen({ showScreen: false, prizeParams: '' })}
                                        > View Promotion </Button>
                                    </div>
                                </div>
                            }
                            {!formik.values.showGenerationError && formik.values.generatedMoments !== '' &&
                                <div>
                                    <Box sx={{ maxWidth: "500px", marginLeft: "30%" }}>
                                        <Breadcrumbs sx={{ textAlign: "center", display: "inline-flex" }}>
                                            <Link underline="hover" fontSize="15px" color="inherit" href="/generateWinningMoments">
                                                Generate Winning Moments
                                            </Link>
                                        </Breadcrumbs>
                                        <Typography variant='h4' sx={{ fontSize: 28, marginTop: "15px" }} >
                                            Generate Winning Moments
                                        </Typography>
                                        <CheckCircleIcon sx={{ fontSize: 80, color: "green", marginTop: "50px", marginBottom: "50px" }} />
                                        <Typography variant='h4' sx={{ marginTop: "10px", marginBottom: "35px" }}>
                                            Winning Moments have been generated and uploaded sucessfully!
                                        </Typography>
                                        <Typography variant='body2' sx={{ marginBottom: "35px" }} >
                                            {downloadMomentsFileInformationText}
                                        </Typography>
                                        <GenericSpinner showSpinner={!momentsGenerated} />
                                        <Box>
                                            <Button
                                                sx={{ width: "155px", marginRight: "10px", fontStyle: "bold", paddingLeft: "5px", paddingRight: "5px" }}
                                                variant="outlined"
                                                component={routerLink} to={`/editPromotion/${configurationId}/winningMomentsTab`}
                                                onClick={() => showGeneratorScreen({ showScreen: false, prizeParams: '' })}
                                            > View Promotion </Button>
                                            <Button
                                                sx={{ minWidth: "155px", marginLeft: "10px", fontStyle: "bold", paddingLeft: "5px", paddingRight: "5px" }}
                                                variant="contained"
                                                disabled={!momentsGenerated}
                                                onClick={() => window.open(JSON.parse(formik.values.generatedMoments).csv, "_blank")}
                                            >Download</Button>
                                        </Box>
                                    </Box>
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </Box>
        </Container >
    )
}

export { GenerateWinningMoments };


