import { Grid, Typography } from '@mui/material';
import { UploadedFilesActionButtons } from "./UploadedFilesActionButtonsComponent";
import { getFileName } from '../fileUploadHelpers';
import { ProgressInfo } from '../../../../types/componentTypes/FileUploadTypes';

const CachedFilesComponent = ({
    backstepDone,
    isEdit,
    cachedFiles,
    progressFiles,
    filePath,
    usePublicDomain,
    handleDeleteFile,
    canEdit,
    setOpenEditModal
}) => {
    return (
        <Grid container={true} className='grid-container'>
            {(backstepDone || isEdit) &&
                cachedFiles &&
                cachedFiles.map((file: any, i: number) => {
                    const fileName =
                        file && getFileName(isEdit, usePublicDomain, file.name, filePath);
                    return file &&
                        !(
                            progressFiles?.some((obj: ProgressInfo) => obj.fileName === decodeURI(file.name))
                        ) ? (
                        <Grid key={i} container className='grid-box'>
                            <Grid item xs={10}>
                                <Typography
                                    variant='body2'
                                    className='existing-file-name'>{`${fileName}`}</Typography>
                            </Grid>
                            <UploadedFilesActionButtons
                                fileName={fileName}
                                filePath={filePath}
                                isEdit={isEdit}
                                usePublicDomain={usePublicDomain}
                                handleDeleteFile={handleDeleteFile}
                                canEdit={canEdit}
                                setOpenEditModal={setOpenEditModal}
                                uploadedFilePath={file.name}
                            />
                        </Grid>
                    ) : null;
                })}
        </Grid>
    )
}

export { CachedFilesComponent }
