import { Storage } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { ProgressInfo, ProgressInfoMapRecord } from '../../../types/componentTypes/FileUploadTypes';

const ALLOWED_STAGES = ['apac', 'latam'];

const makeEncodedUrl = ({
    makeDefaultUrl,
    env,
    path,
    region,
}: {
    makeDefaultUrl: boolean;
    env: string;
    path: string;
    region?: string;
}) =>
    makeDefaultUrl
        ? encodeURI(`https://public.${env}.ngps.coca-cola.com/${path}`)
        : encodeURI(`https://public.${env}.${region}.ngps.coca-cola.com/${path}`);

export const appendPublicDomainName = ({ path, bucketName = awsconfig.aws_user_files_s3_bucket }: { path: string; bucketName?: string }) => {
    const strArr = bucketName.split('-');
    const env = strArr[strArr.length - 1];

    const region = strArr[1];
    const isValidRegion = ALLOWED_STAGES.includes(region);

    if (bucketName.includes('ss')) {
        console.error('Can not save file to SS buckets, saving to default location');
        return makeEncodedUrl({
            makeDefaultUrl: isValidRegion ? false : true,
            env,
            path,
            region: isValidRegion ? region : '',
        });
    }

    if (strArr.length === 5 && isValidRegion) {
        !isValidRegion
            ? console.error('region not found in allowed regions defaulting to default save location')
            : console.log('saving to regional bucket');
        return makeEncodedUrl({
            makeDefaultUrl: isValidRegion ? false : true,
            env,
            path,
            region: isValidRegion ? region : '',
        });
    }

    return makeEncodedUrl({ makeDefaultUrl: true, env, path });
};

export const getFileNameFromPath = (usePublicDomain: boolean, stringValue: string, filePath: string) => stringValue?.replace(`${usePublicDomain ? appendPublicDomainName({
    path: filePath,
    bucketName: awsconfig.aws_user_files_s3_bucket
}) : filePath}/`, '')

export const getFileName = (isEdit: boolean, usePublicDomain: boolean, stringValue: string, filePath: string) => {
    if (isEdit) {
        return stringValue?.substring(stringValue.lastIndexOf("/") + 1);
    }
    return getFileNameFromPath(usePublicDomain, stringValue, filePath)
}

export const addFilePlaceholder = (progressFiles: ProgressInfoMapRecord | ProgressInfo[] , file: File, progressKey: string) => {
    const progressFilesUpdate = progressKey ? {...progressFiles} : [...progressFiles as ProgressInfo[]];
    if(progressKey) {
        if (!progressFilesUpdate[progressKey]) {
            progressFilesUpdate[progressKey] = [];
        }
        progressFilesUpdate[progressKey].push({ fileName: file.name, percent: 0 });
    } else {
        (progressFilesUpdate as ProgressInfo[]).push({ fileName: file.name, percent: 0 })
    }
    return progressFilesUpdate
} // to delete

export const validateFileType = (fileType: string, customAllowedFileTypes: string[]) => {
    if (customAllowedFileTypes) {
        return customAllowedFileTypes.includes(fileType);
    }

    const fileExtension = fileType.slice(0, fileType.indexOf('/'));
    switch (fileExtension) {
        case 'image':
            return ['image/jpeg', 'image/png', 'image/svg+xml'].includes(fileType);
        case 'text':
            return [
                '',
                'text/plain',
                'text/x-csv',
                'text/csv',
                'application/vnd.ms-excel',
                'application/csv',
                'application/x-csv',
                'text/comma-separated-values',
                'text/x-comma-separated-values',
                'text/tab-separated-values',
            ].includes(fileType);
        default:
            return false;
    }
};

export const configureStorage = (fileAccess) => {
    Storage.configure({
        customPrefix: {
            public: '',
            protected: '',
            private: '',
        },
        level: fileAccess,
    });
};

export const validateFileAndHeader =  async (file, customAllowedFileTypes, headerValidation, headerValidatorFunc, setNotificationState) => {
    const isValidFile = validateFileType(file.type, customAllowedFileTypes);
    let validHeader = true;

    if (headerValidation) {
        try {
            await headerValidatorFunc(file);
        } catch (e) {
            validHeader = false;
        }
    }
    if (!isValidFile || !validHeader) {
        setNotificationState({
            open: true,
            title: 'Error',
            content: !isValidFile
                ? `Invalid file type: ${file.name}`
                : `File validation failed: ${file.name}`,
            level: 'error',
        });
        return false;
    }
    return true
}

export const getUniqueUploadedFiles = (e: React.ChangeEvent<HTMLInputElement>, files: File[], maxFiles: number, setNotificationState, allFiles) => {
    const uploadedFiles = Array.from(e.target.files);
        const uniqueUploadedFiles = uploadedFiles.filter((file: File) => {
            const duplicate = files.some((item: File) => item.name === file.name);
            if (!duplicate) return true;

            setNotificationState({
                open: true,
                title: 'Error',
                content: `File ${file.name} already uploaded`,
                level: 'error',
            });
            return false;
        });

        if (maxFiles && (uniqueUploadedFiles.length > maxFiles || (allFiles && allFiles === maxFiles))) {
            e.preventDefault();
            setNotificationState({
                open: true,
                title: 'Error',
                content: `Cannot upload more than ${maxFiles} file(s)`,
                level: 'error',
            });
            return;
        }
        return uniqueUploadedFiles
}
