import { Box, Typography, InputAdornment, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';

const ExportStatusMessage = ({ message, csvUrl, onCopy }) => {
    return (
        <Box>
            <Typography variant="body1" className="pe-message">
                {message}
            </Typography>
            <TextFieldWrapper
                id="participation-export-csv-url"
                value={csvUrl}
                disabled={true}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton onClick={onCopy} data-testid="copy-signedUrl-iconButton">
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default ExportStatusMessage;
